<template>
  <v-container>
    <div class="d-flex">
      <div class="display-1 mb-2 mr-auto"> Subscriptions </div>
        <v-tooltip
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              @click="showDialog = true"
              v-bind="attrs"
              v-on="on"
              fab
              dark
              x-small
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add New Subscriptions</span>
        </v-tooltip>
      <new-fiware-subscribe v-model="showDialog" @fetch="fetchSubscription(), snackbar.active = true"></new-fiware-subscribe>
    </div>
    <list-item :items="subscribeItems" title="id" icon="mdi-desktop-tower" hasDelete link @delete="deleteItem($event)"></list-item>
    <snack-bar v-model="snackbar.active" :text="snackbar.text"></snack-bar>
  </v-container>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'FiwareSubscribe',
  components: {
    ListItem: () => import('@/components/ListItem'),
    NewFiwareSubscribe: () => import('@/components/fiware/NewFiwareSubscribe'),
    SnackBar: () => import('@/components/SnackBar')
  },
  data: () => ({
    showDialog: false,
    subscribeItems: [],
    snackbar: {
      active: false,
      text: null
    }
  }),
  methods: {
    fetchSubscription: function () {
      api.get('/f/v2/subscriptions/', this.$store.state.firewareServices, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            this.subscribeItems = json
            this.snackbar.text = 'created'
          })
        }
      })
    },
    deleteItem: function (itemName) {
      api.delete(`/f/v2/subscriptions/${itemName}`, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 204) {
          const rm = this.subscribeItems.filter(sunscription => sunscription.id !== itemName)
          this.subscribeItems = rm
          this.snackbar.active = true
          this.snackbar.text = 'deleted'
          // console.log(response)
        }
      })
    }
  },
  computed: {},
  created () {
    this.fetchSubscription()
  }
}
</script>
